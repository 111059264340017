<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { connectToWallet, disconnectWallet } from '@/utils/profile';

const open = async () => await connectToWallet(true, 'main');
const diconnect = async () => await disconnectWallet(true);
</script>

<template>
    <div id="wallet-container" class="wallet-container">
        <template v-if="currentIsConnectedStatus === false">
            <button id="ton-connect" class="action-button" @click="open">
                <p>Авторизироваться</p>
            </button>
        </template>
        <template v-else>
            <button id="ton-connect" class="action-button" @click="diconnect">
                <p>Отключиться</p>
            </button>
        </template>
    </div>
</template>

<style scoped>
    .wallet-container {
        display: flex;
        justify-content: center;
    }

    .action-button {
        width: 18vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: green;
        border: 0.1vw #000000 solid;
        border-radius: 45px;
        color: white;
    }
</style>