<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue"
import { expand } from "./utils/telegram";
import { onMounted } from "vue";
import { currentIsConnectedStatus } from "./utils/tonConnect";

onMounted(() => {
  expand();
})
</script>

<template>
  <div>
    <HeaderComponent v-if="currentIsConnectedStatus"/>
    <Suspense>
      <router-view/>
    </Suspense>
  </div>
</template>

<style>
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #f1f1f1;
  border-radius: 0 0 45px 45px;
}

.page-menu {
  font-size: 1em;
}

.block_payments {
  width: -webkit-fill-available;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.main-container {
  width: -webkit-fill-available;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.container {
  width: -webkit-fill-available;
}

.group {
  margin-bottom: 30px;
  background-color: white;
  padding: 1%;
}

.block-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  text-align: left;
}

.block-table th, .block-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.block-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.button-group button {
  padding: 8px 16px;
  cursor: pointer;
}

.green-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.green-button:hover {
  background-color: darkgreen;
}

.red-button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.red-button:hover {
  background-color: darkred;
}

.side-left-button {
  border-radius: 0 45px 45px 0;
}

.wallet-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.disable-block {
  display: none;
}

.filters {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: 1vw;
    width: 60%;
}

.filter-element {
    width: -webkit-fill-available;
    padding: 1vw;
}
</style>
