const errorConnectMessage = "Unable to establish a connection to the server... Try again later!";

export const getRequest = async (url, isIgnore404) => await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            alert(errorConnectMessage);
        } else if (response.status !== 404) {
            response.json()
            .then(data => {
                alert(JSON.stringify(data.error ?? data));
            })
            .catch(e => {
                alert(e);
            });
        } else if (!isIgnore404) {
            alert(errorConnectMessage)
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const postRequest = async url => await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            alert(errorConnectMessage);
        } else {
            response.json()
            .then(data => {
                alert(JSON.stringify(data.error ?? data));
            })
            .catch(e => {
                alert(e);
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const postForm = async (url, data) => await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    body: data,
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            alert(errorConnectMessage);
        } else {
            response.json()
            .then(data => {
                alert(JSON.stringify(data.error ?? data));
            })
            .catch(e => {
                alert(e);
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const putRequest = async url => await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            alert(errorConnectMessage);
        } else {
            response.json()
            .then(data => {
                alert(JSON.stringify(data.error ?? data));
            })
            .catch(e => {
                alert(e);
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const putRequestWithData = async (url, data) => await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    body: data,
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            alert(errorConnectMessage);
        } else {
            response.json()
            .then(data => {
                alert(JSON.stringify(data.error ?? data));
            })
            .catch(e => {
                alert(e);
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});

export const deleteRequest = async url => await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
}).then(response => 
{
    if (!response.ok) {
        if (response.status === 500) {
            alert(errorConnectMessage);
        } else {
            response.json()
            .then(data => {
                alert(JSON.stringify(data.error ?? data));
            })
            .catch(e => {
                alert(e);
            });
        }
        return null;
    }
    else {
        return response.json();
    }
}).then(data => {
    return data;
}).catch(e => {
    console.error(e);
    return null;
});