import { ref } from "vue";
import { getRequest } from "./restapi";
import { sendTransaction, sendTransactions } from "./tonweb";
import { API_ADDRESS_SERVER } from "@/config";

export const payments = ref(null);
export const count = ref(0);

export const getAllPayments = async () => await getRequest(`${API_ADDRESS_SERVER}/income/get/payments`, false);

export const sendPayment = async (address, ton, paymentID, transactionID) => await sendTransaction(address, ton, paymentID, transactionID);
export const sendPayments = async (payments) => await sendTransactions(payments);