<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { addBlackUser, addWhiteUser, getBlackUsers, getWhiteUsers, removeBlackUser, removeWhiteUser } from '@/utils/users';
import { onBeforeMount, onMounted, ref } from 'vue';
import WalletModal from '../modals/WalletModal.vue';
import { load } from '@/utils/profile';

const whiteUsers = ref([]);
const blackUsers = ref([]);

const whiteID = ref('');
const blackID = ref('');

const reload = async () => {
    whiteUsers.value = await getWhiteUsers();
    blackUsers.value = await getBlackUsers();
};

const addUserToWhiteList = async () => {
    if (whiteID.value === null  || whiteID.value === '' || whiteID.value === " ") {
        alert("Введите ID пользователя!");
    } else {
        await addWhiteUser(whiteID.value);
        whiteID.value = '';
        await reload();
    }
};

const addUserToBlackList = async () => {
    if (blackID.value === null  || blackID.value === '' || blackID.value === " ") {
        alert("Введите ID пользователя!");
    } else {
        await addBlackUser(blackID.value);
        blackID.value = '';
        await reload();
    }
};

const removeUserFromWhiteList = async (id) => {
    await removeWhiteUser(id);
    await reload();
};

const removeUserFromBlackList = async (id) => {
    await removeBlackUser(id);
    await reload();
};

onMounted(async () => {
    await reload();
});

onBeforeMount(async () => {
    await load();
})
</script>

<template>
    <main class="page-menu">
        <template v-if="currentIsConnectedStatus">
            <button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>
            <div class="main-container">
                <div class="lists_container">
                    <div class="container">
                        <div class="group">
                            <h3>Белый список</h3>
                            <table class="block-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Username</th>
                                        <th>Действие</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in whiteUsers" :key="user">
                                        <td data-label="ID">{{ user._id }}</td>
                                        <td data-label="Username">{{ user.username }}</td>
                                        <td data-label="Действие">
                                            <button @click="removeUserFromWhiteList(user._id)">Удалить</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="button-group">
                                <input type="text" id="ID" placeholder="ID" v-model="whiteID"/>
                                <button @click="addUserToWhiteList">Добавить</button>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="group">
                            <h3>Черный список</h3>
                            <table class="block-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Username</th>
                                        <th>Действие</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in blackUsers" :key="user">
                                        <td data-label="ID">{{ user._id }}</td>
                                        <td data-label="Username">{{ user.username }}</td>
                                        <td data-label="Действие">
                                            <button @click="removeUserFromBlackList(user._id)">Удалить</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="button-group">
                                <input type="text" id="ID" placeholder="ID" v-model="blackID"/>
                                <button @click="addUserToBlackList">Добавить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="wallet-button">
                <WalletModal />
            </div>
        </template>
    </main>
</template>

<style>
.lists_container {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 1vw;
    padding: 1vw;
}
</style>