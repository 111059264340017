import { createRouter, createWebHistory } from 'vue-router'
import AuthorizationView from './views/AuthorizationView.vue'
import MainView from '@/views/MainView.vue'
import SettingsView from '@/views/SettingsView.vue'
import BeesView from '@/views/BeesView.vue'
import UsersView from '@/views/UsersView.vue'
import UsersListsView from '@/views/UsersListsView.vue'
import NewsletterView from './views/NewsletterView.vue'
import TasksView from './views/TasksView.vue'
import WalletView from './views/WalletView.vue'

const routes = [
  {
    path: '/',
    name: 'authorization',
    component: AuthorizationView
  },
  {
    path: '/main',
    name: 'main',
    component: MainView
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: WalletView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/bees',
    name: 'bees',
    component: BeesView
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView
  },
  {
    path: '/userslists',
    name: 'userslists',
    component: UsersListsView
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: NewsletterView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
