<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import WalletModal from '@/components/modals/WalletModal.vue';

const route = useRoute();
const router = useRouter();
const activePage = ref(route.name);

const changePage = async page => {
    await router.push({ name: page });
    activePage.value = page;
};

watch(route, (newRoute) => {
    activePage.value = newRoute.name;
});
</script>

<template>
    <header class="header">
        <h1>HoneyHarvest - Admin Panel</h1>
        <div class="wallet-button">
            <WalletModal />
        </div>
        <nav class="navbar">
            <div class="buttons_container">
                <button id="main" :class="['nav_button', { active: activePage === 'main' }]" @click="changePage('main')">Главная</button>
                <button id="wallet" :class="['nav_button', { active: activePage === 'wallet' }]" @click="changePage('wallet')">Кошелек</button>
                <button id="settings" :class="['nav_button', { active: activePage === 'settings' }]" @click="changePage('settings')">Настройки</button>
                <button id="bees" :class="['nav_button', { active: activePage === 'bees' }]" @click="changePage('bees')">Пчелы</button>
                <button id="users" :class="['nav_button', { active: activePage === 'users' }]" @click="changePage('users')">Пользователи</button>
                <button id="userslists" :class="['nav_button', { active: activePage === 'userslists' }]" @click="changePage('userslists')">Списки</button>
                <button id="tasks" :class="['nav_button', { active: activePage === 'tasks' }]" @click="changePage('tasks')">Задачи</button>
                <button id="newsletter" :class="['nav_button', { active: activePage === 'newsletter' }]" @click="changePage('newsletter')">Рассылка</button>
            </div>
        </nav>
    </header>
</template>

<style>
.header {
    text-align: center;
}

.header h1 {
    font-size: 1.5em;
    color: #333;
    width: -webkit-fill-available;
}

.navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
}

.nav_button {
    background-color: blue;
    color: white;
    border-color: transparent;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 1%;
}

.nav_button.active {
    background-color: green;
}

.buttons_container {
    gap: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
</style>