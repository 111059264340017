<script setup>
import { ref, defineExpose } from 'vue';
import { connect, getData } from "@/utils/tonweb";

const secretPhrases = ref(Array(24).fill(''));
const secretPhraseRow = ref('');
const showModal = ref(false);
const isRow = ref(true);

const closeModal = () => {
	showModal.value = false;
};

const openModal = () => {
	showModal.value = true;
};

const changeTab = (isEnable) => {
	isRow.value = isEnable;

	if (!isEnable && secretPhraseRow.value != '' && secretPhraseRow.value != " ") {
		let words = secretPhraseRow.value.split(' ');
		for (let i = 0; i < words.length; i++) {
			secretPhrases.value[i] = words[i];
		}
	}
}

const connectWallet = async () => {
	let str = "";

	try {
		if (isRow.value) {
			str = secretPhraseRow.value;
		} else {
			for (let i = 0; i < secretPhrases.value.length; i++) {
				str += secretPhrases.value[i];

				if (i + 1 < secretPhrases.value.length) {
					str += " "
				}
			}
		}
		
		await connect(str);
		await getDataWallet();
	} catch (e) {
		alert("Некоректно введены секретные слова! Должно быть указано 24 слова!");
		console.error(e)
	}
};

const getDataWallet = async () => await getData();

const submitPhrases = () => {
	connectWallet();
	closeModal();
};

defineExpose({
	openModal
});
</script>

<template>
	<div v-if="showModal" class="modal-overlay">
		<div class="modal-content">
			<button class="modal-close" @click="closeModal">×</button>
			<h2>Введите секретные фразы</h2>
			
			<div class="tabs">
				<button @click="changeTab(true)" class="submit-btn">В строку</button>
				<button @click="changeTab(false)" class="submit-btn">В список</button>
			</div>

			<template v-if="!isRow">
				<div class="phrases-container">
					<div v-for="(phrase, index) in secretPhrases" :key="index" class="phrase-input">
						<label :for="'phrase-' + (index + 1)">{{ index + 1 }}.</label>
						<input 
							type="text" 
							:id="'phrase-' + (index + 1)" 
							v-model="secretPhrases[index]" 
							class="phrase-field" 
						/>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="phrases-container-row">
					<div class="phrase-input-row">
						<input 
							type="text" 
							:id="'phrase-row'" 
							v-model="secretPhraseRow" 
							class="phrase-field-row" 
						/>
					</div>
				</div>
			</template>

			<div class="modal-actions">
				<button @click="submitPhrases" class="submit-btn">Подключить</button>
				<button @click="closeModal" class="cancel-btn">Отмена</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.tabs {
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 5%;
}

.modal-content {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	width: 90%;
	max-width: 500px;
	max-height: 90%;
	overflow-y: auto;
	position: relative;
}

.modal-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.phrases-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

.phrase-input {
	display: flex;
	align-items: center;
}

.phrase-input label {
	margin-right: 10px;
	font-weight: bold;
}

.phrase-field {
	flex: 1;
	padding: 5px;
}

.modal-actions {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.submit-btn {
	background-color: green;
	color: white;
	padding: 10px 20px;
	border: none;
	cursor: pointer;
}

.cancel-btn {
	background-color: red;
	color: white;
	padding: 10px 20px;
	border: none;
	cursor: pointer;
}

.phrases-container-row {
	display: grid;
}

.phrase-input-row {
	display: flex;
	align-items: center;
}

.phrase-input-row label {
	margin-right: 10px;
	font-weight: bold;
}

.phrase-field-row {
	padding: 5px;
	width: -webkit-fill-available;
}
</style>