<script setup>
import { ref, onBeforeMount } from "vue";
import { getAllPayments, sendPayment, sendPayments, payments, count } from "@/utils/payments";
import { load } from '@/utils/profile';
import { address, balance, getData, getWalletAddressInGame, isSendingProccess, updateWalletNewAddressInGame } from "@/utils/tonweb";
import MnemonicModal from '@/components/modals/MnemonicModal.vue';
import { Address, fromNano } from "@ton/core";
import { getBank, bank } from "@/utils/exchange";
import { currentIsConnectedStatus } from "@/utils/tonConnect";

const mnemonicModal = ref(null);

const filterInput = ref('');
const filterSelection = ref('All');

const addressInGame = ref('');

const sendToUser = async (paymentID, transactionID, userAddress, ton) => {
    await load();

    if (address?.value !== null) {
        await sendPayment(userAddress, ton, paymentID, transactionID);
    } else {
        alert("Сначала необходимо подключить кошелек!");
    }
};

const sendToAll = async () => {
    await load();

    if (address?.value !== null) {
        let usersPaymets = [];

        payments.value.forEach(payment => {
            payment.transactions.forEach(transaction => {
                if (!transaction.isCompleted) {
                    usersPaymets.push({
                        id: transaction.id,
                        date: transaction.datetime,
                        address: transaction.userAddress,
                        ton: transaction.income.ton,
                        paymentId: payment._id,
                    });
                }
            });
        });
        
        await sendPayments(usersPaymets);
    } else {
        alert("Сначала необходимо подключить кошелек!");
    }
};

const countAll = () => {
    let countTon = 0;

    payments.value.forEach(payment => {
        payment.transactions.forEach(transaction => {
            if (!transaction.isCompleted) {
                countTon += transaction.income.ton;
            }
        });
    });

    count.value = countTon;
};

const countForUser = (paymentID) => {
    let countTon = 0;

    payments.value.forEach(payment => {
        if (payment._id === paymentID) {
            payment.transactions.forEach(transaction => {
                if (!transaction.isCompleted) {
                    countTon += transaction.income.ton;
                }
            });
        }
    });

    return countTon;
}

const init = async () => {
    await load();
    payments.value = await getAllPayments();
    countAll();
};

const reload = async () => {
    await getBank();
    await init();
    await getData();
}

const sortedList = () => {
    switch(filterSelection.value) {
        case 'Complited': {
            let sortedPayments = [];

            payments.value.forEach(payment => {
                let filtredPayment = JSON.parse(JSON.stringify(payment));
                filtredPayment.transactions = [];

                payment.transactions.forEach(transaction => {
                    if (transaction.isCompleted) {
                        filtredPayment.transactions.push(JSON.parse(JSON.stringify(transaction)));
                    }
                });

                if (filtredPayment.transactions.length > 0) {
                    sortedPayments.push(filtredPayment);
                }
            });

            return sortedPayments;
        }
        case 'Wait': {
            let sortedPayments = [];

            payments.value.forEach(payment => {
                let filtredPayment = JSON.parse(JSON.stringify(payment));
                filtredPayment.transactions = [];

                payment.transactions.forEach(transaction => {
                    if (!transaction.isCompleted) {
                        filtredPayment.transactions.push(JSON.parse(JSON.stringify(transaction)));
                    }
                });

                if (filtredPayment.transactions.length > 0) {
                    sortedPayments.push(filtredPayment);
                }
            });

            return sortedPayments;
        }
        case 'All':
        default: return payments.value;
    }
};

const filteredList = () => {
    let result = [];
    
    if (sortedList() && sortedList().length > 0) {
        result = sortedList().filter(payment =>
            payment._id.toLowerCase().includes(filterInput.value.toLowerCase())
        );
    }

    return result;
};

const paymentsList = () => {
    if (!filteredList().length || filteredList().length == 0) {
        return sortedList();
    } else {
        return filteredList();
    }
}

const getTransactionsCount = () => {
    let transactionsCount = 0;

    if (paymentsList() && paymentsList().length > 0) {
        paymentsList().forEach(payment => {
            transactionsCount += payment.transactions.length;
        });
    }

    return transactionsCount;
};

const convertUserAddress = (userAddress) => {
    if (Address.isRaw(userAddress)) {
        let parsedAddress = Address.parse(userAddress);
        return parsedAddress.toString({
            urlSafe: true,
            bounceable: false,
            testOnly: false
        });
    } else {
        return userAddress
    }
};

const getAddressInGame = async () => {
    addressInGame.value = await getWalletAddressInGame();
};

const updateAddressInGame = async () => {
    let newAddress = await updateWalletNewAddressInGame(addressInGame.value);

    if (newAddress !== null) {
        addressInGame.value = newAddress;
    }
}

onBeforeMount(async () => {
    await getBank();
    await init();
    await getAddressInGame();
});

const openMnemonicModal = () => {
    mnemonicModal.value.openModal();
};
</script>

<template>
<main id="main_menu" class="page-menu">
    <template v-if="currentIsConnectedStatus">
        <button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>

        <div class="main-container" style="padding-left: 5vw;padding-right: 5vw;">
            <div style="width: -webkit-fill-available;">
                <h1>КОШЕЛЕК:</h1>

                <p>Адресс кошелька: {{ address ?? 'Не подключен. Введите секретные фразы!' }}</p>
                <p>Баланс кошелька: {{ fromNano(balance ?? 0) }} TON </p>

                <button class="green-button" @click="openMnemonicModal">Ввести секретные фразы</button>
            </div>
            
            <div style="width: -webkit-fill-available;">
                <h1>БИРЖА:</h1>

                <p>Адрес кошелька биржи:</p>
                <input style="width: -webkit-fill-available;" type="text" placeholder="wallet address" v-model="addressInGame">

                <!-- <template v-if="address !== null && address != '' && (addressInGame !== address)"> -->
                    <!-- <p style="color:red">АДРЕС КОШЕЛЬКА В ИГРЕ ОТЛИЧАЕТСЯ</p> -->
                    <button class="red-button" @click="updateAddressInGame">Обновить адрес</button>
                <!-- </template> -->

                <p>Баланс биржы: {{ bank?.balance?.ton ?? 0 }} TON</p>
                <p>Внесено в биржу: {{ bank?.contributed ?? 0 }} TON</p>
                <p>Выведено из биржи: {{ bank?.withdraw?.ton ?? 0}} TON</p>
                <p>Начислено администратору: {{ bank?.admin_balance?.ton ?? 0}} TON</p>
            </div>
        </div>
        
        <div class="main-container">
            <div class="block_payments">
                <h1>ЗАПРОСЫ НА ВЫВОД:</h1>

                <template v-if="count > 0">
                    <template v-if="isSendingProccess">
                        <button class="red-button">Отправка {{ Number(count.toFixed(3)) }} TON...</button>
                    </template>
                    <template v-else>
                        <button class="green-button" @click="sendToAll">Отправить всем {{ Number(count.toFixed(3)) }} TON</button>
                    </template>
                </template>
                <template v-else>
                    <button class="red-button">Пока нет запросов на выплаты</button>
                </template>
                <div class="container">
                    <div class="filters">
                        <input class="filter-element" type="text" v-model="filterInput" placeholder="Введите ID пользователя" />
                        <select class="filter-element" v-model="filterSelection">
                            <option value="All">Все</option>
                            <option value="Complited">Оплаченные</option>
                            <option value="Wait">Ожидающие</option>
                        </select>
                    </div>
                    <p>Запросы: <h style="color: red; font-weight: bold;">{{ getTransactionsCount() ?? 0}}</h></p>
                    <div v-for="payment in paymentsList()" :key="payment._id" class="group">
                        <h2>User: {{ payment._id }} - {{ Number(countForUser(payment._id).toFixed(3)) }} TON</h2>
                        <table class="block-table">
                            <thead>
                                <tr>
                                    <!-- <th>ID</th> -->
                                    <th>Дата</th>
                                    <th>TON</th>
                                    <th>GH</th>
                                    <th>Статус</th>
                                    <th>Адресс</th>
                                    <th>Действие</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="transaction in payment.transactions" :key="transaction.id">
                                    <!-- <td data-label="ID">{{ transaction.id }}</td> -->
                                    <td data-label="Дата">{{ transaction.datetime }}</td>
                                    <td data-label="TON">{{ transaction.income.ton }}</td>
                                    <td data-label="GH">{{ transaction.income.honey }}</td>
                                    <td data-label="Статус">{{ transaction.status === "complete" ? "Оплачен" : "Ожидает" }}</td>
                                    <td data-label="Адрес"> {{ convertUserAddress(transaction.userAddress) }} </td>
                                    <td data-label="Действие">
                                        <button 
                                            v-if="!transaction.isCompleted"
                                            class="send-btn"
                                            @click="sendToUser(payment._id, transaction.id, transaction.userAddress, transaction.income.ton)"
                                        >
                                            Оплатить
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <MnemonicModal ref="mnemonicModal" />
    </template>
</main>
</template>

<style>
.send-btn {
    background-color: blue;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.send-btn:hover {
    background-color: darkblue;
}
</style>
