<script setup>
import { address, balance, getData, isSendingProccess, withdrawTransaction } from '@/utils/tonweb';
import { fromNano } from '@ton/core';
import { onBeforeMount, ref } from 'vue';
import MnemonicModal from '../modals/MnemonicModal.vue';
import { load } from '@/utils/profile';
import { createTransaction, currentAccount } from '@/utils/tonConnect';

const inputValue = ref(0.0);

const mnemonicModal = ref(null);

const reload = async () => {
    await getData();
}

const openMnemonicModal = () => {
    mnemonicModal.value.openModal();
};

const deposit = async (address, value) => {
    if (address === null) {
        alert('Подключите кошелек биржи для совершения транзакций!');
    } else if (value <= 0) { 
        alert('Значение должно быть больше 0!');
    } else {
        let result = await createTransaction(address, value, `Deposit on ${inputValue.value} TON`);

        if (result === null) {
            alert('Ошибка совершения транзакции!');
        } else {
            alert('Транзакция успешно отправлена!');
        }
        inputValue.value = 0;
    }
}

const withdraw = async (address, value) => {
    if (address === null) {
        alert('Подключите кошелек биржи для совершения транзакций!');
    } else if (value <= 0) { 
        alert('Значение должно быть больше 0!');
    } else {
        await withdrawTransaction(address, value);

        inputValue.value = 0;
    }
}

onBeforeMount(async () => {
    await load();
});
</script>

<template>
    <main>
        <button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>
        <div class="container">
            <div class="main-container">
                <div>
                    <h1>КОШЕЛЕК:</h1>

                    <p>Адресс кошелька: {{ address ?? 'Не подключен. Введите секретные фразы!' }}</p>
                    <p>Баланс кошелька: {{ fromNano(balance ?? 0) }} TON </p>

                    <button class="green-button" @click="openMnemonicModal">Ввести секретные фразы</button>
                </div>
                <div class="container-wallet">
                    <p>Пополнить/Вывести:</p>
                    <input class="amount-deposit-input" type="number" placeholder="Введите сумму..." v-model="inputValue">
                    <div class="container-wallet-buttons">
                        <template v-if="isSendingProccess">
                            <button class="red-button">Отправка...</button>
                        </template>
                        <template v-else>
                            <button class="deposit-button" @click="deposit(address, inputValue)">Пополнить</button>
                            <button class="deposit-button" @click="withdraw(currentAccount.address, inputValue)">Вывести</button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <MnemonicModal ref="mnemonicModal" />
</template>

<style scoped>
.container-wallet {
    font-size: 1vw;
}

.amount-deposit-input {
    font-size: 1vw;
    padding: 0.5vw;
}

.container-wallet-buttons {
    font-size: 1vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
}

.deposit-button {
    font-size: 1vw;
}
</style>