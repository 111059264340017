<script setup>
import { onMounted } from 'vue';
import WalletModal from '../modals/WalletModal.vue';
import { connectToWallet } from '@/utils/profile';

onMounted(() => {
    connectToWallet(true, 'main');
});
</script>

<template>
    <main class="authorization">
        <h1 class="auth-header">АВТОРИЗИРУЙТЕСЬ</h1>
        <WalletModal />
    </main>
</template>

<style>
.authorization {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 10vw;
    gap: 2vw;
} 

.auth-header {
    text-align: center;
    font-size: 2vw;
}

</style>