<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import WalletModal from '../modals/WalletModal.vue';
import { onBeforeMount, ref } from 'vue';
import { postForm } from '@/utils/restapi';
import { API_ADDRESS_SERVER } from '@/config';
import { load } from '@/utils/profile';

const message = ref('');
const picked = ref('AllUsers')
// const selectedFile = ref(null);

// const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//         selectedFile.value = file;
//     }
// };

const sendMessage = async () => {
    if (!message.value) {
        alert('Пожалуйста, заполните сообщение.');
        return;
    }

    const formData = new FormData();
    formData.append('typeUsers', picked.value);
    formData.append('message', message.value);
    //formData.append('image', selectedFile.value);

    try {
        const response = await postForm(`${API_ADDRESS_SERVER}/newsletter/send`, formData);

        if (response) {
            picked.value = 'AllUsers';
            message.value = '';
            //selectedFile.value = null;
        }
    } catch (error) {
        alert(`Произошла ошибка: ${error.message}`);
    }
};

onBeforeMount(async () => {
    await load();
})
</script>

<template>
    <main>
        <template v-if="currentIsConnectedStatus">
            <div class="form-letter">
                <div class="text-block">
                    <div class="label">
                        <h3 class="black">Текст сообщения</h3>
                        <h3 class="red"> * </h3>
                    </div>
                    <textarea class="textbox" name="message" placeholder="Сообщение" v-model="message"></textarea>
                </div>

                <div class="users-block">
                    <div class="label">
                        <h3 class="black">Кому:</h3>
                    </div>
                    <div class="radio-button">
                        <input type="radio" id="all" value="AllUsers" v-model="picked" />
                        <label for="all">Всем пользователям</label>
                    </div>
                    <div class="radio-button">
                        <input type="radio" id="whitelist" value="WhiteListUsers" v-model="picked" />
                        <label for="whitelist">Пользователям белого списка</label>
                    </div>
                </div>

                <!-- <div class="image-block">
                    <div class="label">
                        <h3 class="black">Изображения</h3>
                    </div>
                    <input class="files-input" type="file" name="images" @change="handleFileChange" />
                </div> -->

                <button class="send-button" type="button" @click="sendMessage">Отправить</button>
            </div>
        </template>
        <template v-else>
            <div class="wallet-button">
                <WalletModal />
            </div>
        </template>
    </main>
</template>

<style>
.newsletter-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.form-letter {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 2vw;
    gap: 2vw;
}

.textbox {
    font-size: 0.8vw;
    height: 30vh;
    width: -webkit-fill-available;
    color: black;
    border-radius: 5px;
}

.text-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.image-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.users-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
}

.files-input {
    font-size: 0.8vw;
}

.send-button {
    border-radius: 45px;
    background-color: green;
    color: white;
    padding: 0.5vw;
    width: 15%;
    font-size: 0.8vw;
}

.label {
    display: flex;
}

.black {
    color: black
}

.red {
    color: red;
}
</style>