import { ref } from 'vue';
import { tg } from "@/utils/telegram";
import { TonConnectUI, THEME } from '@tonconnect/ui';
import { beginCell, toNano } from '@ton/ton'
import router from '@/router.js';
import { getRequest } from './restapi';
import { API_ADDRESS_SERVER } from '@/config';

const tonConnectUIInstance = ref(null);

export const currentWallet = ref(null);
export const currentWalletInfo = ref(null);
export const currentAccount = ref(null);
export const currentIsConnectedStatus = ref(false);

export const userDataWithWallet = ref(null);

export const tonConnect = async (isRedirect = false, page = "") => {
    try {
        if (!tonConnectUIInstance.value) {
            tonConnectUIInstance.value = new TonConnectUI({
                manifestUrl: 'https://admin.honeyharvest.ru/tonconnect-manifest.json'
            });

            tonConnectUIInstance.value.uiOptions = {
                twaReturnUrl: 'https://admin.honeyharvest.ru',
                language: 'ru',
                uiPreferences: {
                    theme: THEME.DARK
                }
            };

            tonConnectUIInstance.value.onStatusChange(async () => {
                updateWalletInfo();
                console.log(JSON.stringify(currentWallet.value));
                console.log(JSON.stringify(currentWalletInfo.value));
                console.log(JSON.stringify(currentAccount.value));
                console.log(currentIsConnectedStatus.value);

                if (currentIsConnectedStatus.value && isRedirect && page != "") {
                    let result = await getRequest(`${API_ADDRESS_SERVER}/account/login/${currentAccount.value.address}`, false);
                    if (result !== null) {
                        await router.push({ name: page });
                    } else {
                        tonDisconnect(false);
                        alert('Нет доступа! Авторизизруйтесь через привязанный кошелек к админке');
                    }
                }
            });
        }

        return tonConnectUIInstance.value;
    } catch {
        console.error('Error initializing TonConnect UI');
        return null;
    }
}

export const tonDisconnect = async (isRedirect = false) => {
    if (tonConnectUIInstance.value) {
        await tonConnectUIInstance.value.disconnect();
        if (isRedirect) {
            await router.push({ name: 'authorization' });
        }
    }

    try {
        updateWalletInfo();
        return tonConnectUIInstance.value;
    } catch (e) {
        tg.showAlert(e);
        console.error(e);
    }
}

export const updateWalletInfo = () => {
    currentWallet.value = tonConnectUIInstance.value.wallet;
    currentWalletInfo.value = tonConnectUIInstance.value.walletInfo;
    currentAccount.value = tonConnectUIInstance.value.account;
    currentIsConnectedStatus.value = tonConnectUIInstance.value.connected;
};

export const createTransaction = async (address, ton, itemName) => {
    let body = beginCell()
        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
        .storeStringTail(`${itemName}`) // write our text comment
        .endCell();

    let transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360, // 60 sec
        messages: [
            {
                address: address,
                amount: toNano(ton).toString(),
                payload: body.toBoc().toString("base64") // payload with comment in body
            }
        ]
    }

    try {
        let result = await tonConnectUIInstance.value.sendTransaction(transaction);
        return result;
    } catch (e) {
        alert(e);
        console.error(e);
        return null;
    }
};

export const createTransactions = async (payments) => {
    let messages = [];

    payments.forEach(payment => {
        let transactionName = `Income ${payment.ton} TON for payment ${payment.id} ${payment.date}`

        let body = beginCell()
            .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
            .storeStringTail(`${transactionName}`) // write our text comment
            .endCell();

        let message = {
            address: payment.address,
            amount: toNano(payment.ton).toString(),
            payload: body.toBoc().toString("base64") // payload with comment in body
        }

        messages.push(message);
    });

    let parts = [];
    let subparts = [];
    let counter = 0;
    for (let i = 0; i < messages.length; i++) {
        if (counter >= 3 || counter >= messages.length - 1) {
            parts.push(subparts)
            counter = 0;
            subparts = [];
        } else {
            subparts.push(messages[i]);
            counter++;
        }
    }

    parts.forEach(async part => {
        let transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            messages: part
        };
    
        try {
            let result = await tonConnectUIInstance.value.sendTransaction(transaction);
            return result;
        } catch (e) {
            alert(e);
            console.error(e);
            return null;
        }
    });
};