import { getRequest, postRequest, deleteRequest, postForm } from "./restapi";
import { API_ADDRESS_SERVER } from "@/config";

export const getAllUsers = async () => await getRequest(`${API_ADDRESS_SERVER}/users/get/all`, false);

export const getWhiteUsers = async () => await getRequest(`${API_ADDRESS_SERVER}/whitelist/get/all`, false);
export const addWhiteUser = async (userId) => await postRequest(`${API_ADDRESS_SERVER}/whitelist/add/${userId}`, false);
export const removeWhiteUser = async (userId) => await deleteRequest(`${API_ADDRESS_SERVER}/whitelist/remove/${userId}`, false);

export const getBlackUsers = async () => await getRequest(`${API_ADDRESS_SERVER}/blacklist/get/all`, false);
export const addBlackUser = async (userId) => await postRequest(`${API_ADDRESS_SERVER}/blacklist/add/${userId}`, false);
export const removeBlackUser = async (userId) => await deleteRequest(`${API_ADDRESS_SERVER}/blacklist/remove/${userId}`, false);

export const editUser = async (id, data) => await postForm(`${API_ADDRESS_SERVER}/user/update/${id}`, data);