<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { addTask, editTask, getAllTasks, removeTask } from '@/utils/tasks';
import { onBeforeMount, onMounted, ref } from 'vue';
import WalletModal from '../modals/WalletModal.vue';
import { load } from '@/utils/profile';

const originalTasks = ref([]);
const tasks = ref([]);

const newTask = ref({
    _id: 0,
    name: "",
    link: "",
    reward: null,
    channel_id: ""
});

const reload = async () => {
    const fetchedTasks = await getAllTasks();
    originalTasks.value = JSON.parse(JSON.stringify(fetchedTasks)); // Копируем данные для отмены изменений
    tasks.value = fetchedTasks;
    newTask.value._id = tasks.value.length;
};

const saveChanges = async (id, data) => {
    await editTask(id, JSON.stringify(data));
    await reload();
};

const addNewTask = async () => {
    if (newTask.value.name === "" || newTask.value.name === " ") {
        alert("Введите название группы!");
    } else if (newTask.value.link === "" || newTask.value.link === " ") {
        alert("Введите ссылку на канал!");
    } else if (newTask.value.reward === null || newTask.value.reward <= 0) {
        alert('Награда должна быть больше 0!')
    } else if (newTask.value.channel_id === "" || newTask.value.channel_id === " ") {
        alert('Введите ID канала!')
    } else {
        await addTask(JSON.stringify(newTask.value));
        await reload();
        cleatNewTask();
    }
};

const deleteTask = async (id) => {
    await removeTask(id);
    await reload();
};

const cleatNewTask = () => {
    newTask.value = {
        _id: tasks.value.length,
        name: "",
        link: "",
        reward: null,
        channel_id: ""
    };
};

const cancelChanges = () => {
    tasks.value = JSON.parse(JSON.stringify(originalTasks.value));
};

onMounted(async () => {
    await reload();
});

onBeforeMount(async () => {
    await load();
})
</script>

<template>
    <main class="page-menu">
        <template v-if="currentIsConnectedStatus">
            <button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>
            <div class="main-container">
                <div class="block_payments">
                    <div class="container">
                        <div class="group">
                            <table class="block-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Название</th>
                                        <th>Ссылка</th>
                                        <th>Награда (GH)</th>
                                        <th>ID канала</th>
                                        <th>Действия</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="task in tasks" :key="task">
                                        <td data-label="ID">{{ task._id }}</td>
                                        <td data-label="Название"><input class="field" v-model="task.name" /></td>
                                        <td data-label="Ссылка"><input class="field" v-model="task.link" /></td>
                                        <td data-label="Награда"><input class="field" v-model="task.reward" /></td>
                                        <td data-label="ID канала"><input class="field" v-model="task.channel_id" /></td>
                                        <td data-label="Действия">
                                            <div class="tasks_actions">
                                                <button @click="saveChanges(task._id, task)">Обновить</button>
                                                <button @click="deleteTask(task._id)">Удалить</button>
                                                <button @click="cancelChanges">Отмена</button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-label="ID">{{ newTask._id }}</td>
                                        <td data-label="Название"><input class="field" v-model="newTask.name" placeholder="Название канала"/></td>
                                        <td data-label="Ссылка"><input class="field" v-model="newTask.link" placeholder="Ссылка на канал"/></td>
                                        <td data-label="Награда"><input class="field" type="number" v-model="newTask.reward" placeholder="Награда за выполнение"/></td>
                                        <td data-label="ID канала"><input class="field" v-model="newTask.channel_id" placeholder="ID канала"/></td>
                                        <td data-label="Действия">
                                            <div class="tasks_actions">
                                                <button @click="addNewTask">Добавить</button>
                                                <button @click="cleatNewTask">Очистить</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="wallet-button">
                <WalletModal />
            </div>
        </template>
    </main>
</template>

<style scoped>
.field {
    width: -webkit-fill-available;
}

.tasks_actions {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}
</style>