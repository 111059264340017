<script setup>
import { getSettings, editSetting } from '@/utils/settings';
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { onBeforeMount, onMounted, ref } from 'vue';
import WalletModal from '../modals/WalletModal.vue';
import { load } from '@/utils/profile';

const originalSettings = ref([]);
const settings = ref([]);

onBeforeMount(async () => {
    await load();
})

onMounted(async () => {
    const fetchedSettings = await getSettings();
    originalSettings.value = JSON.parse(JSON.stringify(fetchedSettings)); // Копируем данные для отмены изменений
    settings.value = fetchedSettings;
});

const reload = async () => {
    const fetchedSettings = await getSettings();
    originalSettings.value = JSON.parse(JSON.stringify(fetchedSettings)); // Копируем данные для отмены изменений
    settings.value = fetchedSettings;
};

const saveChanges = async () => {
    for (let i = 0; i < settings.value.length; i++) {
        const setting = settings.value[i];
        const originalSetting = originalSettings.value[i];
        if (setting.value !== originalSetting.value) {
            await editSetting(setting._id, setting.value);
            originalSetting.value = setting.value; // Обновляем оригинальные данные
        }
    }
};

const cancelChanges = () => {
    settings.value = JSON.parse(JSON.stringify(originalSettings.value)); // Возвращаем оригинальные данные
};
</script>

<template>
    <main class="page-menu">
        <template v-if="currentIsConnectedStatus">
            <button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>
            <div class="main-container">
                <div class="block_payments">
                    <div class="container">
                        <div class="group">
                            <table class="block-table">
                                <thead>
                                    <tr>
                                        <th>Настройка</th>
                                        <th>Значение</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="setting in settings" :key="setting.key">
                                        <td data-label="Настройка">{{ setting.title }}</td>
                                        <td data-label="Значение">
                                            <template v-if="setting._id === 6 || setting._id === 11">
                                                <div class="radio-button">
                                                    <input type="radio" id="Enable_{{setting._id}}" value="true" v-model="setting.value" />
                                                    <label for="Enable_{{setting._id}}">Да</label>
                                                </div>
                                                <div class="radio-button">
                                                    <input type="radio" id="Disable_{{setting._id}}" value="false" v-model="setting.value" />
                                                    <label for="Disable_{{setting._id}}">Нет</label>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <input type="number" v-model="setting.value" />
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="button-group">
                                <button @click="saveChanges">Обновить</button>
                                <button @click="cancelChanges">Отмена</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="wallet-button">
                <WalletModal />
            </div>
        </template>
    </main>
</template>